import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import { selectIsMobile } from '@redux/viewport/selectors';
import { compose } from '@xo-union/react-css-modules';
import { Button } from '@xo-union/tk-component-buttons';
import { Field, Form, type PseudoEvent } from '@xo-union/tk-component-fields';
import { IconButton } from '@xo-union/tk-component-icons';
import { useAtom, useSetAtom } from 'jotai';
import { debounce } from 'lodash';
import React, { useMemo, useRef } from 'react';
import AnalyticsEvents from '../../../constants/analytics';
import { fieldValueAtom, resetSemanticSearchResponseAtom } from './atoms';
import { MobileSemanticSuggestions } from './components/MobileSemanticSuggestions';
import { SemanticSuggestions } from './components/SemanticSuggestions';
import { useIsStorefrontSearch } from './hooks/use-is-storefront-search';
import { useRemoveSemanticTerm } from './hooks/use-remove-semantic-term';
import { useSemanticRedirect } from './hooks/use-semantic-redirect';
import { useSemanticSearch } from './hooks/use-semantic-search';
import { useSemanticSuggestions } from './hooks/use-semantic-suggestions';
import { useSourcePage } from './hooks/use-source-page';
import { useTrackSearch } from './hooks/use-track-search';
import Styles from './listings-search-bar.styles.scss';

const fieldClasses = compose({
	container: Styles.fieldContainer,
	input: Styles.fieldInput,
});

export const ListingsSearchBar = () => {
	const { handleSemanticSearch, trackSearchButtonClicked } =
		useSemanticSearch();
	const [fieldValue, setFieldValue] = useAtom(fieldValueAtom);
	const isMobile = useAppSelector((state) => selectIsMobile(state));
	const categoryCode = useAppSelector((state) => state.category.code);
	const stateCode = useAppSelector((state) => state.location.stateCode);
	const city = useAppSelector((state) => state.location.city);
	const marketCode = useAppSelector((state) => state.settings.marketCode);
	const resetSemanticSearchResponse = useSetAtom(
		resetSemanticSearchResponseAtom,
	);
	const { getSuggestions, closeSuggestions, openSuggestions } =
		useSemanticSuggestions();
	const closeButtonRef = useRef<HTMLButtonElement>(null);
	const trackSearch = useTrackSearch();
	const isStorefrontSearch = useIsStorefrontSearch();
	const semanticRedirect = useSemanticRedirect();
	const { track } = useAnalyticsContext();
	const removeSemanticTerm = useRemoveSemanticTerm();
	const sourcePage = useSourcePage();

	const onInputChange = useMemo(
		() =>
			debounce((searchString: string) => {
				track({
					event: AnalyticsEvents.VENDOR_SEARCH_INTERACTION,
					properties: {
						searchString,
						action: 'typing',
						sourceContent: 'free_search_box',
						sourcePage,
						vendorCategoryCode: categoryCode,
						location: `${city}, ${stateCode}`,
					},
				});
			}, 1000),
		[categoryCode, city, stateCode, track, sourcePage],
	);

	const handleSearchTextChange = async (
		e: React.ChangeEvent<HTMLInputElement>,
	) => {
		const value = e.target.value;
		setFieldValue(value);
		onInputChange(value);
		getSuggestions(value);
	};

	const handleFieldClick = () => {
		if (
			document.activeElement === closeButtonRef?.current &&
			fieldValue.length > 0
		) {
			trackSearch('clear_search_text', { categoryCode, marketCode });
			resetSemanticSearchResponse();
			closeSuggestions();
			setFieldValue('');
			removeSemanticTerm();
		}
	};

	const handleSubmit = (pseudoEvent: PseudoEvent) => {
		if (!pseudoEvent.valid) {
			return;
		}
		if (isMobile && fieldValue.length > 0) {
			openSuggestions();
			return;
		}
		trackSearchButtonClicked();
		if (isStorefrontSearch) {
			semanticRedirect(fieldValue);
		} else {
			handleSemanticSearch(fieldValue);
		}
		closeSuggestions();
		removeSemanticTerm();

		if (document.activeElement instanceof HTMLElement) {
			document.activeElement.blur();
		}
	};

	const handleMobileOnClick = useMemo(
		() =>
			debounce(() => {
				isMobile &&
					track({
						event: AnalyticsEvents.VENDOR_SEARCH_INTERACTION,
						properties: {
							product: 'marketplace',
							action: 'open_search_menu',
							sourcePage,
							sourceContent: 'free_search_box',
							vendorCategoryCode: categoryCode,
							displayMarketCode: marketCode,
						},
					});
				if (fieldValue.length === 0) {
					openSuggestions();
				}
			}, 1000),
		[
			categoryCode,
			fieldValue,
			isMobile,
			marketCode,
			openSuggestions,
			track,
			sourcePage,
		],
	);

	return (
		<div>
			<div onClick={handleMobileOnClick} onKeyDown={handleMobileOnClick}>
				<Form className={Styles.semanticSearchForm} onSubmit={handleSubmit}>
					<Field
						classes={fieldClasses}
						label="Search vendors, styles, details"
						name="search..."
						onChange={handleSearchTextChange}
						onFocus={() => {
							if (fieldValue.length > 0 && !isMobile) {
								openSuggestions();
								getSuggestions(fieldValue);
							}
						}}
						validations={{ required: true }}
						value={fieldValue}
						subTextOnInvalid=""
						onClick={() => openSuggestions()}
					/>
					<IconButton<HTMLButtonElement>
						aria-label="clear search field"
						className={
							fieldValue.length > 0
								? Styles.clearButton
								: Styles.clearButtonHidden
						}
						name="close_circle"
						onClick={handleFieldClick}
						ref={closeButtonRef}
						size="md"
					/>
					<Button
						className={Styles.semanticSearchButton}
						color="primary"
						iconName="search"
						size="lg"
						type="submit"
						aria-label="Submit search"
					/>
				</Form>
			</div>
			{isMobile ? <MobileSemanticSuggestions /> : <SemanticSuggestions />}
		</div>
	);
};
