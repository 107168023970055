import { PhotoPictureWrapper } from '@components/photo-picture-wrapper';
import { Img } from '@xo-union/tk-component-picture';
import { Body1, Caption } from '@xo-union/tk-ui-typography';
import { useAtomValue } from 'jotai';
import React, { type FC, useMemo } from 'react';
import { NO_IMAGE_PATH } from '../../../pages/Storefront/components/Media/constants';
import { fieldValueAtom } from '../atoms';
import { useTrackSuggestions } from '../hooks/use-track-suggestions';
import type { StorefrontSuggestion as StorefrontSuggestionType } from './SemanticSuggestionList';
import Styles from './SemanticSuggestionList.styles.scss';
import { MixedWeightText } from './mixed-weight-text';

interface StorefrontSuggestionProps {
	suggestion: StorefrontSuggestionType;
	idx: number;
}

const StorefrontSuggestion: FC<StorefrontSuggestionProps> = ({
	suggestion,
	idx,
}) => {
	const fieldValue = useAtomValue(fieldValueAtom);
	const { trackStorefrontSuggestion } = useTrackSuggestions();
	const { info } = suggestion;
	const { category, location, name, url, photoUrl } = info;
	const photoId = useMemo(() => photoUrl?.split('/').pop(), [photoUrl]);
	const trackClick = () => trackStorefrontSuggestion(suggestion, idx);
	return (
		<li className={Styles.item}>
			<a
				href={url}
				onClick={trackClick}
				rel="noreferrer noopener"
				target="_blank"
			>
				<div className={Styles.storefrontWrapper}>
					<div className={Styles.storefrontImageContainer}>
						{photoId ? (
							<PhotoPictureWrapper
								id={photoId}
								imgProps={{
									className: Styles.storefrontImage,
								}}
								lazy
								smartCrop
								width={60}
								height={60}
							/>
						) : (
							<div className={Styles.storefrontNoImageWrapper}>
								<Img
									className={Styles.storefrontNoImage}
									src={NO_IMAGE_PATH}
									alt="None"
								/>
							</div>
						)}
					</div>
					<div className={Styles.storefrontContentContainer}>
						<Caption className={Styles.category} size="sm">
							{category}
						</Caption>
						<Body1>
							<MixedWeightText text={name} highlight={fieldValue} />
						</Body1>
						<Caption size="lg" className={Styles.location}>
							{location}
						</Caption>
					</div>
				</div>
			</a>
		</li>
	);
};

interface StorefrontSuggestionListProps {
	suggestions: StorefrontSuggestionType[];
}

export const StorefrontSuggestionList = ({
	suggestions,
}: StorefrontSuggestionListProps) => {
	return (
		<ul className={Styles.list}>
			{suggestions.map((s, idx) => (
				<StorefrontSuggestion key={s.key} suggestion={s} idx={idx} />
			))}
		</ul>
	);
};
