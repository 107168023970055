import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import { useAtomValue } from 'jotai';
import { fieldValueAtom } from '../atoms';
import type {
	FacetOrTermSuggestion,
	StorefrontSuggestion,
} from '../components/SemanticSuggestionList';
import { useSourcePage } from './use-source-page';

export function useTrackSuggestions() {
	const { track } = useAnalyticsContext();
	const fieldValue = useAtomValue(fieldValueAtom);
	const sourcePage = useSourcePage();
	const vendorCategoryCode = useAppSelector((state) => state.category.code);
	const displayMarketCode = useAppSelector(
		(state) => state.settings.marketCode,
	);
	const baseProperties = {
		product: 'marketplace',
		action: 'search_completed',
		searchText: fieldValue,
		sourcePage,
		sourceContent: 'free_search_box',
		vendorCategoryCode,
		displayMarketCode,
	};
	return {
		trackFacetSuggestion: (suggestion: FacetOrTermSuggestion, idx: number) => {
			track({
				event: 'Vendor Search Interaction',
				properties: {
					...baseProperties,
					selection: 'facet_suggestion',
					facetId: suggestion.facetId,
					position: idx + 1,
					searchTerm: suggestion.searchTerm,
					suggestion: suggestion.label,
				},
			});
		},
		trackTermSuggestion: (suggestion: FacetOrTermSuggestion, idx: number) => {
			track({
				event: 'Vendor Search Interaction',
				properties: {
					...baseProperties,
					selection: 'semantic_suggestion',
					position: idx + 1,
					searchTerm: suggestion.searchTerm,
					suggestion: suggestion.label,
				},
			});
		},
		trackStorefrontSuggestion: (
			suggestion: StorefrontSuggestion,
			idx: number,
		) => {
			track({
				event: 'Vendor Search Interaction',
				properties: {
					...baseProperties,
					selection: 'vendor_suggestion',
					position: idx + 1,
					suggestion: suggestion.info.name,
				},
			});
		},
	};
}
