import { useAppSelector } from '@redux/hooks';
import { useAtomValue } from 'jotai';
import { isSemanticSearchCalledAtom } from '../atoms';

export const useSourcePage = () => {
	const isSemanticSearchCalled = useAtomValue(isSemanticSearchCalledAtom);
	const vendor = useAppSelector((state) => state.vendor.vendor);

	if (vendor) {
		return vendor.purchaseStatus === 'PAID'
			? 'paid storefront'
			: 'unpaid storefront';
	}

	if (isSemanticSearchCalled) {
		return 'semantic_results';
	}

	return 'category results';
};
