import React from 'react';
import { useCloseDropdown } from '../hooks/use-close-dropdown';
import { useSemanticSuggestions } from '../hooks/use-semantic-suggestions';
import { SemanticSuggestionList } from './SemanticSuggestionList';
import Styles from './SemanticSuggestions.styles.scss';
import { StorefrontSuggestionList } from './StorefrontSuggestionList';

export interface DividerProps {
	searchSuggestionsLength: number;
	storefrontSuggestionsLength: number;
}

export const Divider = (props: DividerProps) => {
	const { searchSuggestionsLength, storefrontSuggestionsLength } = props;

	if (searchSuggestionsLength === 0 || storefrontSuggestionsLength === 0) {
		return null;
	}

	return <hr className={Styles.divider} />;
};
export const SemanticSuggestions = () => {
	const {
		searchSuggestions,
		storefrontSuggestions,
		isSemanticSuggestionsOpen,
	} = useSemanticSuggestions();

	const targetRef = useCloseDropdown();

	if (
		!isSemanticSuggestionsOpen ||
		(!searchSuggestions.length && !storefrontSuggestions.length)
	) {
		return null;
	}

	return (
		<div className={Styles.wrapper} ref={targetRef}>
			<SemanticSuggestionList suggestions={searchSuggestions} />
			<Divider
				searchSuggestionsLength={searchSuggestions.length}
				storefrontSuggestionsLength={storefrontSuggestions.length}
			/>
			<StorefrontSuggestionList suggestions={storefrontSuggestions} />
		</div>
	);
};
