import React, { memo, useMemo } from 'react';

interface MixedWeightTextProps {
	text: string;
	highlight: string;
}

export const MixedWeightText = memo(
	({ text, highlight }: MixedWeightTextProps) => {
		const parts = useMemo(
			() => text.split(new RegExp(`(${highlight})`, 'i')),
			[highlight, text],
		);

		if (!highlight || highlight.length < 3) {
			return <b>{text}</b>;
		}

		return (
			<>
				{parts.map((part) => {
					if (part === '') {
						return '';
					}
					const key = `${part || ''}-${Math.random()}`;
					if (part.toLowerCase() === highlight.toLowerCase()) {
						return <span key={key}>{part}</span>;
					}
					return <b key={key}>{part}</b>;
				})}
			</>
		);
	},
);
