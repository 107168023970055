import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useCallback } from 'react';
import type { CategoryCode } from 'types/Category';
import { useSourcePage } from './use-source-page';

export type TrackingActions = 'clear_search_text';
export type SourcePage =
	| 'category results'
	| 'semantic_results'
	| 'paid storefront'
	| 'unpaid storefront';
export type AdditionalArgs = {
	categoryCode: CategoryCode;
	marketCode: string | undefined;
};

export const trackClearSearchText = (
	sourcePage: SourcePage,
	{ categoryCode, marketCode }: AdditionalArgs,
) => {
	return {
		event: 'Vendor Search Interaction',
		properties: {
			product: 'marketplace',
			action: 'clear_search_text',
			sourcePage,
			sourceContent: 'free_search_box',
			vendor_category_code: categoryCode,
			displayMarketCode: marketCode,
		} as const,
	};
};

export const useTrackSearch = () => {
	const { track } = useAnalyticsContext();
	const sourcePage = useSourcePage();

	return useCallback(
		(action: TrackingActions, args: AdditionalArgs) => {
			const trackFunctions = {
				clear_search_text: () =>
					trackClearSearchText(sourcePage, {
						categoryCode: args.categoryCode,
						marketCode: args.marketCode,
					}),
			};

			if (trackFunctions[action]) {
				return track({
					...trackFunctions[action](),
				});
			}
		},
		[track, sourcePage],
	);
};
