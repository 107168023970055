import { storefrontSearchSelector } from '@redux/experiments/selectors/storefront-search';
import { useAppSelector } from '@redux/hooks';

export const useIsStorefrontSearch = () => {
	const isStorefrontPage = useAppSelector(
		(state) => state.page.pageType === 'storefront',
	);
	const storefrontSearch = useAppSelector(storefrontSearchSelector);
	return isStorefrontPage && storefrontSearch === 'storefront-search';
};
