import { semanticSuggestions } from '@api/semanticSearch';
import { useAppSelector } from '@redux/hooks';
import { useAtom } from 'jotai';
import debounce from 'lodash/debounce';
import { useCallback, useMemo } from 'react';
import {
	isSemanticSuggestionsOpenAtom,
	semanticSearchTypeaheadAtom,
} from '../atoms';
import type { StorefrontSuggestion } from '../components/SemanticSuggestionList';

export const useSemanticSuggestions = () => {
	const [isSemanticSuggestionsOpen, setIsSemanticSuggestionsOpen] = useAtom(
		isSemanticSuggestionsOpenAtom,
	);
	const categoryCode = useAppSelector((state) => state.category.code);
	const [semanticSearchTypeahead, setSemanticSearchTypeahead] = useAtom(
		semanticSearchTypeaheadAtom,
	);
	const resetSuggestions = useCallback(() => {
		setSemanticSearchTypeahead({
			storefronts: [],
			semanticSearchResults: [],
		});
	}, [setSemanticSearchTypeahead]);

	const getSuggestions = useMemo(() => {
		return debounce(async (searchText) => {
			if (searchText.length < 3) {
				resetSuggestions();
				return;
			}

			const res = await semanticSuggestions({
				searchText,
				categoryCode,
			});

			const storefronts = (res?.data?.search?.profiles || []).map((s) => {
				return {
					id: s.id,
					name: s.name,
					photoUrl: s.storefrontCard?.url,
					url: s.siteUrls[0]?.relativeUri,
					address: s.location.address.address1,
					city: s.location.address.city,
					state: s.location.address.state,
					category: s.categoryInfo?.seo?.plural?.term || '',
				};
			});
			const semanticSearchResults = res?.data?.semanticSuggestions || [];
			setSemanticSearchTypeahead({
				storefronts,
				semanticSearchResults,
			});
		}, 300);
	}, [categoryCode, setSemanticSearchTypeahead, resetSuggestions]);

	const searchSuggestions = useMemo(() => {
		return semanticSearchTypeahead.semanticSearchResults.map((s) => {
			return {
				facetId: s?.facetId,
				facetSlug: s?.facetSlug,
				facetCategorySlug: s?.facetCategorySlug,
				key: (s.facet ?? s.semanticTerm) as string,
				label: (s.facet ?? s.semanticTerm) as string,
				type: s.facet ? ('facet' as const) : ('term' as const),
				searchTerm: s.searchTerm,
			};
		});
	}, [semanticSearchTypeahead.semanticSearchResults]);

	const storefrontSuggestions: StorefrontSuggestion[] = useMemo(() => {
		return semanticSearchTypeahead.storefronts.map((s) => {
			const address = s.address ? `${s.address} ` : '';
			return {
				type: 'storefront' as const,
				key: s.name,
				info: {
					name: s.name,
					url: s.url,
					photoUrl: s.photoUrl,
					category: s.category,
					location: `${address}${s.city}, ${s.state}`,
				},
			};
		});
	}, [semanticSearchTypeahead.storefronts]);

	const openSuggestions = () => {
		setIsSemanticSuggestionsOpen(true);
	};

	const closeSuggestions = () => {
		setIsSemanticSuggestionsOpen(false);
		resetSuggestions();
	};

	const closeMobileSuggestions = useCallback(() => {
		if (isSemanticSuggestionsOpen) {
			setIsSemanticSuggestionsOpen(false);
		}
	}, [setIsSemanticSuggestionsOpen, isSemanticSuggestionsOpen]);

	return {
		isSemanticSuggestionsOpen,
		getSuggestions,
		closeSuggestions,
		closeMobileSuggestions,
		openSuggestions,
		searchSuggestions,
		storefrontSuggestions,
	};
};
