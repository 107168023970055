import type { CategoryCode, CategoryFacets } from 'types/Category';

export interface TopTenCategory {
	/** category code */
	code: string;
	/** category name */
	name: string;
	/** category slug */
	urlSlug: string;
	/** category id */
	id: string;
}

interface CategoryConstants {
	TOP_TEN_CATEGORIES: TopTenCategory[];
}

const constants: CategoryConstants = {
	TOP_TEN_CATEGORIES: [
		{
			code: 'BWP',
			name: 'Bridal Salons',
			urlSlug: 'bridal-salons',
			id: 'cc838748-ddbb-463f-b6ea-e08c83d0972c',
		},
		{
			code: 'BEA',
			name: 'Beauty Salons',
			urlSlug: 'beauty-services',
			id: '8aa9a07b-fcd8-46ed-a98f-8f3e12689dd2',
		},
		{
			code: 'CAT',
			name: 'Caterers',
			urlSlug: 'catering',
			id: '1dbe9436-1f5a-49e3-844b-31d9d5f5a5d8',
		},
		{
			code: 'DJS',
			name: 'DJs',
			urlSlug: 'wedding-djs',
			id: '37ffe21a-579f-4100-88c1-ca664f5398ce',
		},
		{
			code: 'FLO',
			name: 'Florists',
			urlSlug: 'florists',
			id: 'dd6dc992-8647-4bba-a17e-feaa684d03b0',
		},
		{
			code: 'REC',
			name: 'Reception Venues',
			urlSlug: 'wedding-reception-venues',
			id: '1572f50a-93b6-40ea-9492-d8cec37f0c44',
		},
		{
			code: 'VID',
			name: 'Videographers',
			urlSlug: 'wedding-videographers',
			id: '4c1e13e9-d500-4f3f-870e-d919beda43cf',
		},
		{
			code: 'BDS',
			name: 'Wedding Bands',
			urlSlug: 'live-wedding-bands',
			id: '92520084-6d29-49e9-a9ab-3b2daef7fa00',
		},
		{
			code: 'WPH',
			name: 'Wedding Photographers',
			urlSlug: 'wedding-photographers',
			id: 'a520ad92-ff3f-4869-a382-92c87a5d809e',
		},
		{
			code: 'PLN',
			name: 'Wedding Planners',
			urlSlug: 'wedding-planners',
			id: 'cc9fb162-595b-42ce-b0f6-45a5b2b1f907',
		},
	],
};

export const CATEGORY_SINGULAR_ABBREVIATED_TERMS: Partial<
	Record<CategoryCode, string>
> = {
	FLO: 'florist',
	VID: 'videographer',
	WPH: 'photographer',
	REC: 'venue',
	CAT: 'caterer',
};

export const CATEGORY_FACETS: CategoryFacets = {
	BEA: {
		name: 'Beauty Services',
		facets: [
			{ label: 'Hair Stylist', facet: 'wedding-beauty=hair-stylist' },
			{ label: 'Makeup Artist', facet: 'wedding-beauty=makeup-artist' },
		],
	},
	BWP: {
		name: 'Bridal Salons',
		facets: [
			{ label: 'Wedding Dress', facet: 'dresses=wedding-dress' },
			{ label: 'Bridesmaid Dress', facet: 'dresses=bridesmaid-dress' },
			{ label: 'Flower Girl Dress', facet: 'dresses=flower-girl-dress' },
			{
				label: 'Mother of the Bride Dress',
				facet: 'dresses=mother-of-the-bride-dress',
			},
			{ label: 'Shower Party Dress', facet: 'dresses=shower-party-dress' },
			{ label: 'Suits', facet: 'suits-and-accessories=suit-for-woman' },
			{ label: 'Tuxedos', facet: 'suits-and-accessories=wedding-tuxedo' },
		],
	},
	CAT: {
		name: 'Catering',
		facets: [
			{ label: 'Food Truck', facet: 'wedding-catering-foods=food-truck' },
			{ label: 'Vegan', facet: 'dietary-options=vegan' },
			{
				label: 'Wedding Buffet',
				facet: 'wedding-catering-foods=wedding-buffet',
			},
		],
	},
	DJS: {
		name: 'DJs',
		facets: [{ label: 'Affordable', facet: 'price_range=affordable' }],
	},
	FLO: {
		name: 'Florists',
		facets: [
			{ label: 'Affordable', facet: 'price_range=affordable' },
			{
				label: 'Wedding Bouquet',
				facet: 'wedding-flower-arrangements=wedding-bouquet',
			},
		],
	},
	WPH: {
		name: 'Photographers',
		facets: [
			{ label: 'Affordable', facet: 'price_range=affordable' },
			{ label: 'Documentary', facet: 'photo-video-styles=documentary' },
			{
				label: 'Film Photography',
				facet: 'wedding-photography-videography=film-photography',
			},
		],
	},
	TRA: {
		name: 'Transportation',
		facets: [
			{ label: 'Wedding Limo', facet: 'wedding-transportation=wedding-limo' },
			{
				label: 'Wedding Shuttle Service',
				facet: 'wedding-transportation=wedding-shuttle-service',
			},
			{
				label: 'Wedding Trolley',
				facet: 'wedding-transportation=wedding-trolley',
			},
		],
	},
	VID: {
		name: 'Videographers',
		facets: [
			{ label: 'Affordable', facet: 'price_range=affordable' },
			{ label: 'Luxury', facet: 'price_range=luxury' },
		],
	},
	PLN: {
		name: 'Wedding Planners',
		facets: [
			{
				label: 'Day of Coordination',
				facet: 'wedding-planning=day-of-coordination',
			},
			{
				label: 'Destination Wedding Planning',
				facet: 'wedding-planning=destination-wedding-planning',
			},
			{
				label: 'Honeymoon Planning',
				facet: 'wedding-planning=honeymoon-planning',
			},
		],
	},
	RNT: {
		name: 'Wedding Rentals',
		facets: [
			{
				label: 'Wedding Chair',
				facet: 'wedding-rentals-equipment=wedding-chair',
			},
			{
				label: 'Wedding Table',
				facet: 'wedding-rentals-equipment=wedding-table',
			},
		],
	},
	REC: {
		name: 'Venues',
		facets: [
			{ label: 'Affordable', facet: 'price_range=affordable' },
			{ label: 'Backyard', facet: 'settings=backyard' },
			{ label: 'Ballroom', facet: 'settings=ballroom' },
			{ label: 'Barn', facet: 'settings=barn' },
			{ label: 'Beach', facet: 'settings=beach' },
			{ label: 'Brewery', facet: 'settings=brewery' },
			{ label: 'Castle', facet: 'settings=castle' },
			{ label: 'City Hall', facet: 'settings=city-hall' },
			{ label: 'Country Club', facet: 'settings=country-club' },
			{ label: 'Estate', facet: 'settings=estate' },
			{ label: 'Forest', facet: 'settings=forest' },
			{ label: 'Garden', facet: 'settings=garden' },
			{ label: 'Historic Venue', facet: 'settings=historic-venues' },
			{ label: 'Hotel', facet: 'settings=hotel-venue' },
			{ label: 'Inexpensive', facet: 'price_range=inexpensive' },
			{ label: 'Library', facet: 'settings=library' },
			{ label: 'Loft', facet: 'settings=loft' },
			{ label: 'Luxury', facet: 'price_range=luxury' },
			{ label: 'Mountain', facet: 'settings=mountain' },
			{ label: 'Museum', facet: 'settings=museum' },
			{
				label: 'Outdoor',
				facet:
					'outdoor_space=uncovered-outdoor-wedding-reception-site+covered-outdoor-wedding-reception-site',
			},
			{ label: 'Park', facet: 'settings=park' },
			{ label: 'Religious', facet: 'settings=religious' },
			{ label: 'Restaurant', facet: 'settings=restaurant' },
			{ label: 'Rooftop', facet: 'settings=rooftop' },
			{ label: 'Small', facet: 'guest_capacity=0-50' },
			{ label: 'Tented', facet: 'settings=tented' },
			{ label: 'Vineyard', facet: 'settings=vineyard' },
		],
	},
};

export default constants;
