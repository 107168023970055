import { selectAssignment } from '@redux/experiments/selectors/index';
import type { StorefrontSearchAssignment } from '@typings/experiments';
import type { State } from 'types/redux';

const storefrontSearchSelector = (state: State) => {
	return selectAssignment(state, [
		'storefrontSearch',
	]) as StorefrontSearchAssignment;
};

export { storefrontSearchSelector };
