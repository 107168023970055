import { useEscape } from '@hooks/useEscape';
import { useAppSelector } from '@redux/hooks';
import { useOutsideClickEffect } from '@xo-union/ui-accessibility';
import { useAtom } from 'jotai';
import { useRef } from 'react';
import { fieldValueAtom } from '../atoms';
import { useSemanticSuggestions } from './use-semantic-suggestions';

export const useCloseDropdown = () => {
	const [fieldValue, setFieldValue] = useAtom(fieldValueAtom);
	const { closeSuggestions } = useSemanticSuggestions();
	const targetRef = useRef<HTMLDivElement>(null);
	const isMobile = useAppSelector((state) => state.viewport.isMobile);

	useEscape(closeSuggestions);

	useOutsideClickEffect(
		{
			onClickOutside: () => {
				if (isMobile) {
					return null;
				}
				closeSuggestions();
				setFieldValue(fieldValue);
			},
		},
		targetRef,
	);

	return targetRef;
};
