import { useAppSelector } from '@redux/hooks';
import { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';

export const useRemoveSemanticTerm = () => {
	const city = useAppSelector((state) =>
		state.location.city?.toLowerCase().replace(/\s+/g, '-'),
	);
	const stateCode = useAppSelector((state) =>
		state.location.stateCode?.toLowerCase(),
	);
	const params = useParams<{ categorySlug?: string; term?: string }>();
	const history = useHistory();

	return useCallback(() => {
		const url = new URL(window.location.href);

		url.searchParams.delete('q');

		if (params.categorySlug && params.term) {
			url.pathname = `/marketplace/${params.categorySlug}-${city}-${stateCode}`;
		}

		history.push(url.pathname + url.search);
	}, [params, history, city, stateCode]);
};
