import type { ABExperimentGroups } from './types';

export const MAP_VIEW_PAGESIZE = 30;

export const STOREFRONT_TITLE_EXPERIMENT_MAP: Record<
	string,
	ABExperimentGroups
> = {
	'3': 'control',
	'6': 'control',
	'7': 'control',
	'0': 'A',
	'4': 'A',
	'5': 'A',
	'1': 'B',
	'2': 'B',
	'8': 'B',
};

export const CATALOG_TITLE_EXPERIMENT_VARIANT_MAP: Record<string, string[]> = {
	a: [
		'ca',
		'ny',
		'wa',
		'ma',
		'md',
		'mn',
		'ok',
		'ia',
		'ks',
		'ne',
		'hi',
		'mt',
		'sd',
		'vt',
	],
	b: [
		'la',
		'oh',
		'tx',
		'pa',
		'ga',
		'nj',
		'az',
		'in',
		'wi',
		'sc',
		'ky',
		'ct',
		'nv',
		'ms',
		'id',
		'nh',
		'ri',
		'nd',
		'wy',
	],
};
