import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useDesktopMedia } from '@xo-union/tk-ui-breakpoints';
import React, { useEffect, useRef, useState, FC } from 'react';
import { connect } from 'react-redux';
import { Track } from 'types/analytics';
import locationToString from '../../../../../utils/locationToString';
import Desktop from './containers/Desktop';
import Mobile from './containers/Mobile';

export interface TooltipInfoProps {
	displayLocationName: string;
	displayMarketCode: string;
	pageNumber: number;
	rightRail?: boolean;
	vendorCategoryCode: Category.CategoryCode;
	vendorResultsCount: number;
}

const trackOpenTooltip = (
	displayLocationName: string,
	displayMarketCode: string,
	pageNumber: number,
	track: Track,
	vendorCategoryCode: Category.CategoryCode,
	vendorResultsCount: number,
) => {
	track({
		event: 'Tooltip Interaction',
		properties: {
			action: 'open tooltip',
			sourceContent: 'listing logic',
			displayLocationName,
			displayMarketCode,
			pageNumber,
			vendorCategoryCode,
			vendorCategoryLevel: 'city',
			vendorResultsCount,
		},
	});
};

const TooltipInfo: FC<TooltipInfoProps> = (props) => {
	const {
		displayLocationName,
		displayMarketCode,
		pageNumber,
		rightRail,
		vendorCategoryCode,
		vendorResultsCount,
	} = props;
	const { track } = useAnalyticsContext();
	const iconRef = useRef<HTMLDivElement>(null);
	const [tooltipOpen, setTooltipOpen] = useState(false);

	useEffect(() => {
		if (tooltipOpen) {
			trackOpenTooltip(
				displayLocationName,
				displayMarketCode,
				pageNumber,
				track,
				vendorCategoryCode,
				vendorResultsCount,
			);
		}
	}, [tooltipOpen]);

	const media = useDesktopMedia();
	const isDesktop = media.yes;

	const tooltipProps = {
		rightRail,
		setTooltipOpen,
		tooltipOpen,
	};

	return isDesktop ? (
		<Desktop ref={iconRef} {...tooltipProps} />
	) : (
		<Mobile ref={iconRef} {...tooltipProps} />
	);
};

const mapStateToProps = (state: Redux.State) => ({
	displayLocationName: locationToString(state.location),
	displayMarketCode: state.settings.marketCode,
	pageNumber: state.search.pagination.page,
	vendorCategoryCode: state.category.code,
	vendorResultsCount: state.search.total,
});

export default connect(mapStateToProps)(TooltipInfo);
