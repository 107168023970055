import { useAppSelector } from '@redux/hooks';
import { validateCitySlug } from '@utils';
import { useCallback, useMemo } from 'react';

export const useSuggestionCategoryLocationPath = () => {
	const vendor = useAppSelector((state) => state.vendor.vendor);
	const { city, stateCode } = useAppSelector(
		(state) => state.searchPageLocation,
	);
	return useMemo(() => {
		if (!vendor || !city || !stateCode) return '';
		const slugifiedCity = validateCitySlug(city);
		return `${vendor.categorySlug}-${slugifiedCity}-${stateCode}`.toLowerCase();
	}, [vendor, city, stateCode]);
};

export const useGetSemanticRedirectUrl = () => {
	const categoryLocationPath = useSuggestionCategoryLocationPath();

	return useCallback(
		(semanticSearchText: string) => {
			if (!categoryLocationPath) return '';
			return `/marketplace/${categoryLocationPath}?q=${semanticSearchText}`;
		},
		[categoryLocationPath],
	);
};

export const useSemanticRedirect = () => {
	const getSemanticRedirectUrl = useGetSemanticRedirectUrl();

	return useCallback(
		(semanticSearchText: string) => {
			window.open(getSemanticRedirectUrl(semanticSearchText), '_self');
		},
		[getSemanticRedirectUrl],
	);
};
