import { useAppDispatch, useAppSelector } from '@redux/hooks';
import {
	addAppliedFilterPillOptions,
	clearAppliedFilterPillOptions,
	clearSidebarFilters,
	commitAppliedFilterPillChanges,
} from '@redux/search';
import { useAtom, useSetAtom } from 'jotai';
import React, { type FC, useCallback, useMemo } from 'react';
import { fieldValueAtom, updateIsSemanticSearchCalled } from '../atoms';
import {
	useGetSemanticRedirectUrl,
	useSuggestionCategoryLocationPath,
} from '../hooks/use-semantic-redirect';
import { useSemanticSearch } from '../hooks/use-semantic-search';
import { useSemanticSuggestions } from '../hooks/use-semantic-suggestions';
import { useTrackSuggestions } from '../hooks/use-track-suggestions';
import Styles from './SemanticSuggestionList.styles.scss';
import { MixedWeightText } from './mixed-weight-text';
interface FacetSuggestionProps {
	suggestion: FacetOrTermSuggestion;
	idx: number;
}

export interface FacetOrTermSuggestion {
	type: 'facet' | 'term';
	key: string;
	label: string;
	facetId?: string;
	facetSlug?: string;
	facetCategorySlug?: string;
	searchTerm?: string;
}

export interface StorefrontSuggestion {
	type: 'storefront';
	key: string;
	info: {
		name: string;
		url: string;
		photoUrl: string;
		category: string;
		location: string;
	};
}

export interface SemanticSuggestionListProps {
	suggestions: FacetOrTermSuggestion[];
}

const FacetSuggestion: FC<FacetSuggestionProps> = (props) => {
	const { suggestion, idx } = props;
	const { trackFacetSuggestion } = useTrackSuggestions();
	const dispatch = useAppDispatch();
	const { closeSuggestions } = useSemanticSuggestions();
	const categoryLocationPath = useSuggestionCategoryLocationPath();
	const facetRedirectUrl = useMemo(() => {
		if (
			!categoryLocationPath ||
			!suggestion.facetSlug ||
			!suggestion.facetCategorySlug
		)
			return '';
		return `/marketplace/${categoryLocationPath}?${suggestion.facetCategorySlug}=${suggestion.facetSlug}`;
	}, [
		categoryLocationPath,
		suggestion.facetCategorySlug,
		suggestion.facetSlug,
	]);

	const { resetSemanticSearchResponse } = useSemanticSearch();
	const isListingsPage = useAppSelector(
		(state) => state.page.pageType === 'city',
	);

	const filters = useAppSelector((state) => state.search.filters.filters);

	const facet = useMemo(() => {
		const filter = filters.find((f) =>
			f.filterOptions.some((o) => o.id === suggestion.facetId),
		);
		const option = filter?.filterOptions.find(
			(o) => o.id === suggestion.facetId,
		);
		if (!filter || !option) {
			return null;
		}
		return {
			id: suggestion.facetId || '',
			categorySlug: filter.slug,
			name: option.name,
			value: option.singular.slug,
		};
	}, [filters, suggestion]);

	const [fieldValue, setFieldValue] = useAtom(fieldValueAtom);

	const applyFacet = useCallback(() => {
		if (!facet) return;
		dispatch(clearAppliedFilterPillOptions());
		dispatch(clearSidebarFilters());
		dispatch(addAppliedFilterPillOptions([facet]));
		dispatch(commitAppliedFilterPillChanges());
	}, [dispatch, facet]);

	const handleClick = () => {
		trackFacetSuggestion(suggestion, idx);
		closeSuggestions();
		setFieldValue('');
		resetSemanticSearchResponse();
		applyFacet();
	};
	if (isListingsPage) {
		return (
			<li className={Styles.item}>
				<button type="button" onClick={handleClick}>
					<MixedWeightText text={suggestion.label} highlight={fieldValue} />
				</button>
			</li>
		);
	}

	return (
		<li className={Styles.item}>
			<a
				href={facetRedirectUrl}
				onClick={() => {
					trackFacetSuggestion(suggestion, idx);
					closeSuggestions();
					setFieldValue(suggestion.label);
				}}
			>
				<MixedWeightText text={suggestion.label} highlight={fieldValue} />
			</a>
		</li>
	);
};

interface SuggestionProps {
	suggestion: FacetOrTermSuggestion;
	idx: number;
}

const Suggestion: FC<SuggestionProps> = (props) => {
	const { suggestion, idx } = props;
	const [fieldValue, setFieldValue] = useAtom(fieldValueAtom);
	const { trackTermSuggestion } = useTrackSuggestions();
	const { closeSuggestions } = useSemanticSuggestions();
	const { handleSemanticSearch } = useSemanticSearch();
	const setIsSemanticSearchCalled = useSetAtom(updateIsSemanticSearchCalled);
	const getSemanticRedirectUrl = useGetSemanticRedirectUrl();
	const urlPath = getSemanticRedirectUrl(suggestion.label);

	const isListingsPage = useAppSelector(
		(state) => state.page.pageType === 'city',
	);

	const handleClick = useCallback(async () => {
		trackTermSuggestion(suggestion, idx);
		closeSuggestions();
		setFieldValue(suggestion.label);
		handleSemanticSearch(suggestion.label);
		setIsSemanticSearchCalled(true);
	}, [
		setFieldValue,
		closeSuggestions,
		setIsSemanticSearchCalled,
		handleSemanticSearch,
		suggestion,
		idx,
		trackTermSuggestion,
	]);

	if (isListingsPage) {
		return (
			<li className={Styles.item}>
				<button type="button" onClick={handleClick}>
					<MixedWeightText text={suggestion.label} highlight={fieldValue} />
				</button>
			</li>
		);
	}
	return (
		<li className={Styles.item}>
			<a
				href={urlPath}
				onClick={() => {
					trackTermSuggestion(suggestion, idx);
					closeSuggestions();
					setFieldValue(suggestion.label);
				}}
			>
				<MixedWeightText text={suggestion.label} highlight={fieldValue} />
			</a>
		</li>
	);
};

export const SemanticSuggestionList: FC<SemanticSuggestionListProps> = ({
	suggestions,
}) => {
	return (
		<ul className={Styles.list}>
			{suggestions.map((s, idx) => {
				if (s.type === 'facet') {
					return <FacetSuggestion key={s.key} suggestion={s} idx={idx} />;
				}
				return <Suggestion key={s.key} suggestion={s} idx={idx} />;
			})}
		</ul>
	);
};
