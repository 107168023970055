import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import { compose } from '@xo-union/react-css-modules';
import { Button } from '@xo-union/tk-component-buttons';
import { Field, Form, type PseudoEvent } from '@xo-union/tk-component-fields';
import { IconButton } from '@xo-union/tk-component-icons';
import { FullscreenModal } from '@xo-union/tk-component-modals';
import { Body1, Subhead } from '@xo-union/tk-ui-typography';
import { useAtom, useSetAtom } from 'jotai';
import { debounce } from 'lodash';
import React, { useCallback, useMemo, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AnalyticsEvents from '../../../../constants/analytics';
import type { Params } from '../../../pages/VendorsSearch/containers/WrappedVendorsSearch';
import { fieldValueAtom, resetSemanticSearchResponseAtom } from '../atoms';
import { useIsStorefrontSearch } from '../hooks/use-is-storefront-search';
import { useSemanticRedirect } from '../hooks/use-semantic-redirect';
import { useSemanticSearch } from '../hooks/use-semantic-search';
import { useSemanticSuggestions } from '../hooks/use-semantic-suggestions';
import { useSourcePage } from '../hooks/use-source-page';
import { useTrackSearch } from '../hooks/use-track-search';
import FormStyles from '../listings-search-bar.styles.scss';
import Styles from './MobileSemanticSuggestions.styles.scss';
import { SemanticSuggestions } from './SemanticSuggestions';

const fieldClasses = compose({
	container: FormStyles.fieldContainer,
	input: FormStyles.fieldInput,
});

export const MobileSemanticSuggestions = () => {
	const [fieldValue, setFieldValue] = useAtom(fieldValueAtom);
	const closeButtonRef = useRef<HTMLButtonElement>(null);
	const categoryCode = useAppSelector((state) => state.category.code);
	const city = useAppSelector((state) => state.location.city);
	const marketCode = useAppSelector((state) => state.settings.marketCode);
	const stateCode = useAppSelector((state) => state.location.stateCode);
	const location = `${city}, ${stateCode}`;
	const selectedCategoryName = useAppSelector((state) => state.category.name);
	const resetSemanticSearchResponse = useSetAtom(
		resetSemanticSearchResponseAtom,
	);

	const {
		isSemanticSuggestionsOpen,
		getSuggestions,
		closeSuggestions,
		closeMobileSuggestions,
	} = useSemanticSuggestions();

	const { handleSemanticSearch, trackSearchButtonClicked } =
		useSemanticSearch();
	const semanticRedirect = useSemanticRedirect();
	const isStorefrontSearch = useIsStorefrontSearch();
	const trackSearch = useTrackSearch();
	const categoryInLocation = `${selectedCategoryName} in ${location}`;
	const params: Params = useParams();
	const history = useHistory();
	const sourcePage = useSourcePage();

	const { track } = useAnalyticsContext();

	const onInputChange = useMemo(
		() =>
			debounce((searchString: string) => {
				track({
					event: AnalyticsEvents.VENDOR_SEARCH_INTERACTION,
					properties: {
						searchString,
						action: 'typing',
						sourceContent: 'free_search_box',
						sourcePage,
						vendorCategoryCode: categoryCode,
						displayMarketCode: marketCode,
						product: 'marketplace',
					},
				});
			}, 1000),
		[categoryCode, track, marketCode, sourcePage],
	);

	const handleMobileClose = useCallback(() => {
		closeMobileSuggestions();
	}, [closeMobileSuggestions]);

	const handleSearchTextChange = async (
		e: React.ChangeEvent<HTMLInputElement>,
	) => {
		const value = e.target.value;
		setFieldValue(value);
		onInputChange(value);
		getSuggestions(value);
	};

	const handleSubmit = (pseudoEvent: PseudoEvent) => {
		if (!pseudoEvent.valid) {
			return;
		}
		trackSearchButtonClicked();
		if (isStorefrontSearch) {
			semanticRedirect(fieldValue);
		} else {
			handleSemanticSearch(fieldValue);
		}
		closeSuggestions();
		if (params.term) {
			history.push(
				`/marketplace/${params.categorySlug}-${city?.toLowerCase().replace(/\s+/g, '-')}-${stateCode?.toLowerCase()}`,
			);
		}
	};

	const handleFieldClick = () => {
		if (
			document.activeElement === closeButtonRef?.current &&
			fieldValue.length > 0
		) {
			trackSearch('clear_search_text', { categoryCode, marketCode });
			resetSemanticSearchResponse();
			closeSuggestions();
		}
	};

	if (!isSemanticSuggestionsOpen) {
		return null;
	}

	return (
		<FullscreenModal
			onClose={handleMobileClose}
			showLogo={false}
			classes={compose({ header: Styles.header, container: Styles.container })}
		>
			<div className={Styles.wrapper} data-testid="mobile-semantic-suggestions">
				<Subhead bold className={Styles.subhead}>
					Search
				</Subhead>
				<Body1 className={Styles.categoryLocation}>{categoryInLocation}</Body1>
				<Form className={Styles.form} onSubmit={handleSubmit}>
					<Field
						classes={fieldClasses}
						label={'Search vendors, styles, details'}
						name="search..."
						onChange={handleSearchTextChange}
						validations={{ required: true }}
						value={fieldValue}
						subTextOnInvalid=""
						id="semantic-search-field"
					/>
					<IconButton<HTMLButtonElement>
						aria-label="clear search field"
						className={
							fieldValue.length > 0
								? FormStyles.clearButton
								: FormStyles.clearButtonHidden
						}
						name="close_circle"
						onClick={handleFieldClick}
						ref={closeButtonRef}
						size="md"
					/>
					<Button
						className={FormStyles.semanticSearchButton}
						color="primary"
						iconName="search"
						size="lg"
						type="submit"
						aria-label="Submit search"
					/>
				</Form>
				<SemanticSuggestions />
			</div>
		</FullscreenModal>
	);
};
