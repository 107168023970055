import { isSemanticSearchCalledAtom } from '@components/listings-search-bar/atoms';
import { useListingsSections } from '@hooks/use-listings-sections';
import { useAppSelector } from '@redux/hooks';
import { Body1, Body2 } from '@xo-union/tk-ui-typography';
import { useAtomValue } from 'jotai';
import React, { useMemo, type FC } from 'react';
import TooltipInfo from '../containers/ResultsHeader/TooltipInfo';
import Styles from './SponsoredResultsDisclaimer.styles.scss';

interface SponsoredResultsDisclaimerProps {
	rightRail?: boolean;
}

export const SponsoredResultsDisclaimer: FC<
	SponsoredResultsDisclaimerProps
> = ({ rightRail }) => {
	const vendors = useAppSelector((state) => state.search.vendors);
	const isSemanticSearchCalled = useAtomValue(isSemanticSearchCalledAtom);
	const hasSponsoredResults = useMemo(() => {
		return isSemanticSearchCalled || vendors[0]?.purchaseStatus === 'PAID';
	}, [isSemanticSearchCalled, vendors]);
	const isListingsSections = useListingsSections();

	if (!isListingsSections) {
		return null;
	}

	if (rightRail) {
		return (
			<div className={Styles.rightRailWrapper}>
				<Body2 className={Styles.rightRailHeader}>Sponsored results</Body2>
				<TooltipInfo rightRail />
			</div>
		);
	}

	if (!hasSponsoredResults) {
		return null;
	}

	return (
		<div className={Styles.wrapper}>
			<Body1 bold>Sponsored results</Body1>
			<TooltipInfo />
		</div>
	);
};
